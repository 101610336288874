<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="success" icon v-bind="attrs" v-on="on">
          <v-icon>done_outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="title"
            >Onboarding Record submitted:
            {{ approval.updatedTime | formatDateTime }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" sm="10" xl="8">
              <v-row>
                <v-col>
                  <v-radio-group
                    v-model="approval.orderTablet"
                    :mandatory="false"
                  >
                    <v-radio
                      disabled
                      :label="$t('Common.use_tablet')"
                      value="false"
                    ></v-radio>
                    <v-radio
                      disabled
                      :label="$t('Common.order_tablet')"
                      value="true"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-checkbox
                    color="takein"
                    disabled
                    :hide-details="true"
                    v-model="approval.complimentaryVideoShoot"
                    :label="$t('Common.request_complimentary_video_shoot')"
                    dense
                    class="mt-4"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    color="takein"
                    disabled
                    :hide-details="true"
                    v-model="approval.complimentaryPaidMarketing"
                    :label="$t('Common.request_complimentary_paid_marketing')"
                    dense
                    class="mt-4"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div v-if="approval.approvalDate">
            Approved at {{ approval.approvalDate | formatDateTime }}
          </div>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Close</v-btn
          >
          <v-btn
            color="success darken-1"
            :disabled="approval.status == 'approved'"
            @click="approve"
            >Approve</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: {
    action: {
      type: String
    },
    uid: {
      type: String
    }
  },
  data() {
    return {
      dialog: false,
      approval: {}
    }
  },
  computed: {
    ...mapGetters({
      myProfile: 'profile'
    })
  },
  async mounted() {
    if (this.action === 'onboard') {
      this.dialog = true
    }
    const data = await this.$store.dispatch('getOnboarding', { uid: this.uid })
    this.approval = data || {}
  },
  methods: {
    approve() {
      const payload = {
        approvalStatus: 'approved',
        type: 'chefs',
        id: this.uid
      }
      this.$store.dispatch('updateAccount', payload).then(() => {
        this.approval.status = 'approved'
        this.approval.uid = this.uid
        this.approval.approvalDate = moment.utc().valueOf()
        this.$store.dispatch('saveOnboarding', this.approval).then(() => {})
        this.$store.dispatch('setMessage', { title: 'Apprvoed', body: '' })
      })
      this.dialog = false
    }
  }
}
</script>
